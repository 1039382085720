import React from 'react';
import Routes from './routes';

import './App.css';

const App = () => {    
  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
